<template>
  <div class="cross-roads">
    <new-user-consents-component
      v-if="showConsent && isEnabledConsentFF"
      @onClick="consentButtonAction"
    />
    <div
      v-else
      class="cross-roads__loader"
    >
      <img :src="loadingGifUrl">
      <p class="cross-roads__loader-text">
        {{ $t('logincheck.loading') }}
      </p>
    </div>
  </div>
</template>

<script>
import * as Sentry from '@sentry/vue';
import { AccountClient } from '@/services/models/accounts';
import { AuthClient } from '@/services/models/authentication';
import { updateAuthToken } from '@/services/axios-client';
import { userDomain } from '@/domain/user';
import { CookieService } from '@/services/cookie';
import { mParticleMetric } from '@/metrics';
import { featureFlagsService } from '@/services/feature-flags';
import { LOGIN_ACTIONS_CONSTANTS } from '@/domain/login';
import { sessionServiceDomain } from '@/domain/session';
import { ROUTER_PATHS } from '@/router/paths';
import { URL_CONSTANTS } from '@/externalURLs';

const REDIRECT_TO = {
    [LOGIN_ACTIONS_CONSTANTS.DOWNLOAD]: ROUTER_PATHS.ACCOUNT_ACTIVATED_SUCCESS,
    [LOGIN_ACTIONS_CONSTANTS.DOWNLOAD_FLAGSHIP]: ROUTER_PATHS.DOWNLOAD_FLAGSHIP,
    [LOGIN_ACTIONS_CONSTANTS.CHECKOUT]: ROUTER_PATHS.CHECKOUT,
    [LOGIN_ACTIONS_CONSTANTS.FLAGSHIP_CHECKOUT]: ROUTER_PATHS.FLAGSHIP_CHECKOUT,
    [LOGIN_ACTIONS_CONSTANTS.LOGIN]: ROUTER_PATHS.LOGIN_SUCCESSFUL,
    [LOGIN_ACTIONS_CONSTANTS.LOGOUT]: ROUTER_PATHS.LOGIN_SUCCESSFUL,
    [LOGIN_ACTIONS_CONSTANTS.ACCOUNT]: ROUTER_PATHS.USER_ACCOUNT,
    [LOGIN_ACTIONS_CONSTANTS.UNDEFINED]: ROUTER_PATHS.USER_ACCOUNT,
    [LOGIN_ACTIONS_CONSTANTS.AUTHORIZE]: ROUTER_PATHS.AUTHORIZE,
    [LOGIN_ACTIONS_CONSTANTS.PROMOTION]: ROUTER_PATHS.UNLOCK_PROMOTION,
    [LOGIN_ACTIONS_CONSTANTS.NEXTGEN]: ROUTER_PATHS.FLAGSHIP_LOGIN_SUCCESS,
    [LOGIN_ACTIONS_CONSTANTS.AIBETAPROGRAM]: ROUTER_PATHS.AI_BETA_PROGRAM_ACCEPTED,
    [LOGIN_ACTIONS_CONSTANTS.STORE]: ROUTER_PATHS.STORE,
    [LOGIN_ACTIONS_CONSTANTS.TTSING]: ROUTER_PATHS.TTSING,
    [LOGIN_ACTIONS_CONSTANTS.TUNA]: ROUTER_PATHS.TUNA,
    [LOGIN_ACTIONS_CONSTANTS.VMSTUDIO]: ROUTER_PATHS.VMSTUDIO,
    [LOGIN_ACTIONS_CONSTANTS.VMGO]: ROUTER_PATHS.VMGO,
    [LOGIN_ACTIONS_CONSTANTS.VM_SOUNDBOARD]: ROUTER_PATHS.VM_SOUNDBOARD,
    [LOGIN_ACTIONS_CONSTANTS.VM_SOUNDBOARD_DEV]: ROUTER_PATHS.VM_SOUNDBOARD,
    [LOGIN_ACTIONS_CONSTANTS.VM_SOUNDBOARD_STA]: ROUTER_PATHS.VM_SOUNDBOARD,
    [LOGIN_ACTIONS_CONSTANTS.COUPON]: ROUTER_PATHS.COUPON,
    [LOGIN_ACTIONS_CONSTANTS.GIFT]: ROUTER_PATHS.GIFT,
    [LOGIN_ACTIONS_CONSTANTS.BETAPROGRAM]: ROUTER_PATHS.BETA_PROGRAM_ACCEPTED,
    [LOGIN_ACTIONS_CONSTANTS.VOICEROOM]: ROUTER_PATHS.VOICEROOM,
    [LOGIN_ACTIONS_CONSTANTS.BEG_GIFTING]: ROUTER_PATHS.BEG_GIFTING,
    [LOGIN_ACTIONS_CONSTANTS.DOWNLOAD_MOBILE]: ROUTER_PATHS.REDIRECT_MOBILE_DOWNLOAD,
    [LOGIN_ACTIONS_CONSTANTS.DOWNLOAD_MOBILE_FLAGSHIP]: ROUTER_PATHS.REDIRECT_MOBILE_DOWNLOAD,
};

const EXTERNAL_REDIRECT_TO = {
    [LOGIN_ACTIONS_CONSTANTS.HALLOWEEN]: ROUTER_PATHS.EXTERNAL_HALLOWEEN,
};

export default {
    components: {
        NewUserConsentsComponent: () =>
            import(
                /* webpackChunkName: "NewUserConsentsComponent" */ '@/components/NewUserConsentsComponent/NewUserConsentsComponent.vue'
            ),
    },
    data() {
        return {
            showConsent: false,
            action: '',
            extraData: '',
            loadingGifUrl: URL_CONSTANTS.VOICEMOD_LOADING_GIF,
            errorRedirectTimeout: null,
        };
    },
    computed: {
        isEnabledConsentFF() {
            return featureFlagsService.getFeatureFlag('SHOW_CONSENTS');
        },
        isAuthenticatedUser() {
            return CookieService.getCookie('access_token');
        },
        refreshToken() {
            return CookieService.getCookie('refresh_token');
        },
    },
    async beforeMount() {
        Sentry.addBreadcrumb({ message: 'CRV - Started on beforeMount CrossRoadsView.vue', level: 'info' });
        const { action, isNew, userId, code } = this.$route.query;
        this.action = action;
        Sentry.addBreadcrumb({ message: `CRV - User action: ${action}`, level: 'info' });
        mParticleMetric.login({ customerid: userId });

        if (isNew && this.showConsent) {
            this.showConsent = JSON.parse(isNew);
        }
        Sentry.addBreadcrumb({ message: `CRV - User isNew: ${isNew} - showConsent: ${this.showConsent}`, level: 'info' });

        const validCookies = !!CookieService.getCookie('access_token') && !!CookieService.getCookie('refresh_token');
        Sentry.addBreadcrumb({
            message: `CrossRoads: cookies: ${validCookies}, code:${!!code}`,
            level: 'info',
        });

        if (!this.isAuthenticatedUser && code) {
            Sentry.addBreadcrumb({ message: 'CRV - User is not authenticated', level: 'info' });
            await this.getAccessToken(code);
            Sentry.addBreadcrumb({ message: 'CRV - Access token request finished', level: 'info' });
        }

        Sentry.addBreadcrumb({ message: `CRV - Asking for user info with userId: ${userId}`, level: 'info' });
        const {data: userInfo} = await AccountClient.getUserInfo(userId);

        Sentry.addBreadcrumb({ message: `CRV - User info: ${userInfo}`, level: 'info' });

        const serializedUserInfo = userDomain.serializer({...userInfo, userId});
        CookieService.setCookie('userId', userId);
        sessionStorage.setItem('userInfo', JSON.stringify(serializedUserInfo));
        this.$store.dispatch('user_info_store/axn_setUserInfo', serializedUserInfo);
        Sentry.addBreadcrumb({ message: 'CRV - User info saved on store, cookies and session storage', level: 'info' });
        if (window.dataLayer) {
            window.dataLayer.push({event: 'signUpSuccess'});
        }
        Sentry.addBreadcrumb({ message: 'CRV - check marketing consent', level: 'info' });
        const marketingConsent = sessionStorage.getItem('marketing-consent');
        if (marketingConsent) {
            Sentry.addBreadcrumb({ message: 'CRV - marketing consent already saved', level: 'info' });
            const userConsent = userDomain.generateConsent({marketing: true});
            Sentry.addBreadcrumb({ message: `CRV - User consent: ${userConsent}`, level: 'info' });
            Sentry.addBreadcrumb({ message: `CRV - Updating user consents with userId: ${userId}`, level: 'info' });
            await AccountClient.updateUserConsents(userId, userConsent);
            Sentry.addBreadcrumb({ message: `CRV - User consents updated with userId: ${userId}`, level: 'info' });
        }

        if (this.showConsent && this.isEnabledConsentFF) {
            Sentry.addBreadcrumb({ message: 'CRV - Showing consents and exiting before redirect', level: 'info' });
            return;
        }
        Sentry.addBreadcrumb({ message: 'CRV - Checking redirect', level: 'info' });
        this.checkRedirect();
    },
    mounted() {
        Sentry.addBreadcrumb({ message: 'CRV - Started on mounted CrossRoadsView.vue', level: 'info' });
        const REDIRECT_TO_ERROR_DELAY = 500000;
        const { payload } = this.$route.query;
        Sentry.addBreadcrumb({ message: `CRV - Getting payload from query string for userId: ${this.$route.query.userId}`, level: 'info' });
        Sentry.addBreadcrumb({ message: 'CRV - Setting timeout to redirect to error view', level: 'info' });
        this.errorRedirectTimeout = setTimeout(() => {
            Sentry.addBreadcrumb({ message: 'CRV - Timeout to redirect to error view triggered', level: 'info' });
            Sentry.captureMessage('CRV - Timeout to redirect to error view triggered');
            mParticleMetric.track(mParticleMetric.EVENTS.ACCOUNTS_WEB_UNEXPECTED_ERROR_OCCURRED, {
                login_error_reason: 'redirect_to_error_timeout',
                error_info: payload ? payload : this.action,
            });
            this.$router.push(ROUTER_PATHS.ACCOUNT_ACTIVATED_ERROR);
        }, REDIRECT_TO_ERROR_DELAY);
    },
    methods: {
        async getAccessToken(code) {
            Sentry.addBreadcrumb({ message: 'CRV - Started on getAccessToken CrossRoadsView.vue', level: 'info' });
            const { data } = await AuthClient.getAccessToken(code);
            Sentry.addBreadcrumb({ message: 'CRV - Access token received', level: 'info' });
            updateAuthToken(data.access_token);
            Sentry.addBreadcrumb({ message: 'CRV - Access token updated', level: 'info' });
            sessionServiceDomain.setLoginCookies(data);
            Sentry.addBreadcrumb({ message: 'CRV - Login cookies set', level: 'info' });
        },
        checkRedirect() {
            Sentry.addBreadcrumb({ message: 'CRV - Started on checkRedirect CrossRoadsView.vue', level: 'info' });
            const { payload } = this.$route.query;
            Sentry.addBreadcrumb({ message: `CRV - checkRedirect with payload: ${payload}`, level: 'info' });
            if (payload) {
                const { redirect, extraData } = JSON.parse(payload);

                if (extraData) {
                    this.extraData = extraData;
                }
                Sentry.addBreadcrumb({ message: `CRV - Trying to redirect to: ${redirect}`, level: 'info' });
                this.redirectTo(redirect);
                return;
            }
            Sentry.addBreadcrumb({ message: `CRV - Trying to redirect without payload to: ${this.action}`, level: 'info' });
            this.redirectTo(this.action);
        },
        redirectTo(redirectTo) {
            if (redirectTo in EXTERNAL_REDIRECT_TO) {
                Sentry.addBreadcrumb({ message: `CRV - External redirect: ${EXTERNAL_REDIRECT_TO[redirectTo]}`, level: 'info' });
                window.location.assign(EXTERNAL_REDIRECT_TO[redirectTo]);
                Sentry.addBreadcrumb({ message: `CRV - External redirect done to: ${EXTERNAL_REDIRECT_TO[redirectTo]}`, level: 'info' });
                clearTimeout(this.errorRedirectTimeout);
                Sentry.addBreadcrumb({ message: 'CRV - Timeout to redirect to error view cleared', level: 'info' });
                return;
            }

            if (!REDIRECT_TO[redirectTo]) {
                Sentry.addBreadcrumb({ message: `CRV - No redirect found, redirecting to: ${REDIRECT_TO[LOGIN_ACTIONS_CONSTANTS.ACCOUNT]}`, level: 'info' });
                this.$router.push({
                    path: REDIRECT_TO[LOGIN_ACTIONS_CONSTANTS.ACCOUNT],
                    query: {
                        ...this.$route.query,
                        ...this.extraData,
                    },
                });
                Sentry.addBreadcrumb({ message: `CRV - Redirected to: ${REDIRECT_TO[LOGIN_ACTIONS_CONSTANTS.ACCOUNT]}`, level: 'info' });
                clearTimeout(this.errorRedirectTimeout);
                Sentry.addBreadcrumb({ message: 'CRV - Timeout to redirect to error view cleared', level: 'info' });
                return;
            }
            Sentry.addBreadcrumb({ message: `CRV - Internal redirect found, redirecting to: ${REDIRECT_TO[redirectTo]}`, level: 'info' });
            this.$router.push({
                path: REDIRECT_TO[redirectTo],
                query: {
                    ...this.$route.query,
                    ...this.extraData,
                },
            });
            Sentry.addBreadcrumb({ message: `CRV - Redirected to: ${REDIRECT_TO[redirectTo]}`, level: 'info' });
            clearTimeout(this.errorRedirectTimeout);
            Sentry.addBreadcrumb({ message: 'CRV - Timeout to redirect to error view cleared', level: 'info' });
        },
        async consentButtonAction(consents) {
            Sentry.addBreadcrumb({ message: 'CRV - Started on consentButtonAction CrossRoadsView.vue', level: 'info' });
            await this.sendAcceptedConsent(consents),
            Sentry.addBreadcrumb({ message: 'CRV - Accepted consents sent', level: 'info' });

            this.goToSuccessView();
        },
        async sendAcceptedConsent(consents) {
            Sentry.addBreadcrumb({ message: 'CRV - Started on sendAcceptedConsent CrossRoadsView.vue', level: 'info' });
            const { userId } = this.$route.query;
            const userConsents = userDomain.generateConsent(consents);
            Sentry.addBreadcrumb({ message: `CRV - Sending user consents: ${userConsents}`, level: 'info' });
            await AccountClient.updateUserConsents(userId, userConsents);
            Sentry.addBreadcrumb({ message: `CRV - User consents updated with userId: ${userId}`, level: 'info' });
            mParticleMetric.track(mParticleMetric.EVENTS.CONSENT_BUTTON_CLICK, { consents: userConsents });
        },
        goToSuccessView() {
            Sentry.addBreadcrumb({ message: 'CRV - Started on goToSuccessView CrossRoadsView.vue', level: 'info' });
            this.$router.push({
                path: REDIRECT_TO[this.action], query: this.$route.query });
            Sentry.addBreadcrumb({ message: `CRV - Redirected to: ${REDIRECT_TO[this.action]}`, level: 'info' });
            clearTimeout(this.errorRedirectTimeout);
            Sentry.addBreadcrumb({ message: 'CRV - Timeout to redirect to error view cleared', level: 'info' });
        },
    },
};
</script>

<style src="./CrossRoadsView.scss" lang="scss">
</style>
