const getFeatureFlag = (flagName) => {
    const flag = process.env[`VUE_APP_${flagName}`];

    if (flag) {
        return flag;
    }

    return false;
};

const featureFlagsService = {
    getFeatureFlag,
};

export { featureFlagsService };